@import "./themes/colors";
@import "./breakpoints";
$orange: map-get($solid-colors, "orange") !default;

.main-content-wrap {
    background: rgba(242, 242, 242, 0.5);
}

.btn-grad-cm {background-image: linear-gradient(to right, #FF512F 0%, #F09819  51%, #FF512F  100%)}
.btn-grad-cm {
    margin: 10px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;            
    box-shadow: 0 0 20px #eee;
    border-radius: 10px;
    display: inline-block;
}

.btn-grad-cm:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
}


.btn-grad-cm-gray {background-image: linear-gradient(to right, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.2)  51%, rgba(0,0,0,0.6)  100%)}
.btn-grad-cm-gray {
    margin: 10px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;            
    box-shadow: 0 0 20px #eee;
    border-radius: 10px;
    display: inline-block;
}

.btn-grad-cm-gray:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
}

.clear {
    clear: both;
}

.ubs-title {
    color: #000000;
    font-weight: bold;
}

.ubs-bt {
    color: #fff;
    font-weight: bold;
    // border-radius: 20px;
    padding: 7px 20px;
    cursor: pointer;
}

.ubs-bt-organge {
    background-color: $orange;
}

.ubs-bt-organge:hover {
    background-color: $orange;
}

.ubs-bt-organge:hover {
    color: #fff;
}

.ubs-bt-gray {
    background-color: rgba(0,0,0,0.5);
}

.ubs-bt-gray:hover {
    background-color: rgba(0,0,0,0.5);
}

.ubs-bt-outline {
    border: 2px solid #000;
    color: #000;
    background: #fff;
}

.mt-20 {
    margin-top: 20px !important;
}

.mb-20 {
    margin-bottom: 20px !important;
}

        
.show-desktop {
    display: none;
    @include breakpoint(desktop) {
        display: inline-block;
    }
}

.show-mobile {
    display:  inline-block;
    @include breakpoint(desktop) {
        display: none;
    }
}

.menu-toggle {
    display: flex;
    left: 10px;
    right: unset;
    @include breakpoint(desktop) {
        display: none !important;
    }
}

.logo {
    margin-left: 30px !important;
    // @include breakpoint(mobileonly) {
    //     margin-left: 60px !important;
    // }
}

.logout-mobile {
    @include breakpoint(desktop) {
        display: none !important;
    }
}

.clear {
    clear: both;
}

ul.navigation-left {
    .nav-item {
        &.active {
            background: rgba(0,0,0,0.1);
        }
    }
}

div[id*="react-select"][id*="listbox"] {
    z-index: 200;
}

.form-control  {
    background: #E6E6E6;
    border: 0;
}

.main-content-wrap.podcast-sidenav-open {
    width: 100%;
    @include breakpoint(desktop) {
        width: calc(100% - 250px);
    }
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  }