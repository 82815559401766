.card-body {
    padding: 10px !important;
}

.header-card-general {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    margin-left: auto !important;
    margin-right: auto !important;
}

.first-publish-cover {
    width: 45%;
    border-radius: 5px;
}

.bttn-1-first-publish {
    background-color: white !important;
    border-color: black !important;
}

.bttn-1-first-publish:hover {
    background-color: white !important;
}

.bttn-general-first-publish {
    border-radius: 20px !important;
}