.dropdown-menu.scrollable {
  max-height: 450px; 
  overflow-y: auto;
  max-height: 500px;
  width: 550px;
  min-height: 130px;
}

.notification-text {
  padding-left: 15px;
  max-width: 400px;
}

.notification-item {
  position: relative;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  max-width: 100%;

  &.unread {
    background-color: #eeeeee; //cor de fundo para notificações não lidas
    &:hover {
      background-color: #eeeeee; // Adicione a cor desejada ao passar o mouse
    }
  }

  .unread-indicator { //cor bolinha ao lado da imagem para mensagens não lidas
    position: absolute;
    top: 50%;
    left: 8px;
    transform: translateY(-50%);
    width: 7px;
    height: 7px;
    background-color: #ec090d;
    border-radius: 100%;
  }

  margin-bottom: 2px;

  .notification-item:last-child {
    margin-bottom: 0;
  }

  &:hover {
    background-color: inherit !important; // Remove o efeito de hover
  }

  &:active {
    background-color: inherit !important; // Remove o efeito de clique
    color: inherit !important;
  }
}

.ellipsis-button {
  color: #808080 !important;
}

.ellipsis-button-container {
  margin-left: auto;
  position: absolute;
  top: 0%;
  right: 0;
  display: flex;
  flex-direction: column;
}

.notifications-container {
  transition: opacity 0.5s ease-in-out;
}

.remove-animation {
  animation: removeAnimation 0.5s ease-in-out;
}

@keyframes removeAnimation {
  0% {
    opacity: 1;
    height: auto;
    margin: 15px 0;
  }

  100% {
    opacity: 0;
    height: 0;
    margin: 0;
  }
}

//cel
@media (max-width: 768px) {
  .notification-text {
    font-size: 11px;
    padding: 12px;
  }

  .ellipsis-button-container {
    margin-right: 16px;

    .ellipsis-button {
      width: 10px;
    }
  }

  .dropdown-menu.scrollable {
    max-width: 690%;
  }
}

@media (max-width: 445px) {
  .dropdown-menu.scrollable {
    max-width: 535%;
  }
}

.bell-icon-container {
  position: relative;

  .red-dot {
    position: absolute;
    top: 0;
    right: 0;
    width: 10px;
    height: 10px;
    background-color: red;
    border-radius: 50%;
  }
}