.sidebar-panel,
.main-content-wrap,
.main-header {
  transition: all 0.2s ease-in;
}

.app-admin-wrap.sidebar-compact-onhover {
  .sidebar-compact-switch {
    background: $primary;
    span {
      left: 14px;
      background: $white;
    }
  }
}
.bg-off-white{
  background-color: #f9f9f9;
}
