.wait-request-component {
    .wait-request-backdrop {
        position: fixed;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.5);
        left: 0;
        top: 0;
        z-index: 9999;
    }

    .wait-request-pins {
        position: fixed;
        z-index: 999999;
        top: 50%;
        left: 50%;
    }
    .centered-progress-bar {
        position: fixed;
        z-index: 999999;
        top: 50%;
        width: 50%;
        left: 25%;
        font-size: 14px;
        font-weight: 600;
        color: white;
        .progress {
            margin-top: 5px;
            font-size: 14px;
            font-weight: 600;
        }
    }
}