@import "../variables";
@import "colors";
$blue: map-get($solid-colors, "blue") !default;
$indigo: map-get($solid-colors, "indigo") !default;
$purple: map-get($solid-colors, "purple") !default;
$pink: map-get($solid-colors, "pink") !default;
$red: map-get($solid-colors, "red") !default;
$orange: map-get($solid-colors, "orange") !default;
$yellow: map-get($solid-colors, "yellow") !default;
$green: map-get($solid-colors, "green") !default;
$teal: map-get($solid-colors, "teal") !default;
$cyan: map-get($solid-colors, "cyan") !default;
$primary-base: $orange;
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #eee !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #bbb !default;
$gray-600: rgba(0,0,0,0.6) !default;
$gray-700: rgba(0,0,0,0.7) !default;
$gray-800: rgba(0,0,0,0.8) !default;
$gray-900: rgba(0,0,0,0.9) !default;
$black: rgba(0,0,0,1) !default;

$primary: $primary-base !default;
$secondary: $gray-800 !default;
$success: $green !default;
$info: $blue !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-500 !default;
$dark: $gray-900 !default;
$background: $white !default;
$foreground: $gray-900 !default;
$menu-toggle: rgba(255,255,255,1);
$dark-bg: #10163a;

$dark-forground: #262c49;

$dark-heading: #c2c6dc;
$heading: darken($foreground, 8%);
$colors: (
  purple: $purple,
  pink: $pink,
  red: $red,
  orange: $orange,
  yellow: $yellow,
  green: $green,
  teal: $teal,
  cyan: $cyan,
  white: $white,
  gray: $gray-600,
  gray-dark: $gray-800,
) !default;
$theme-colors: (
  primary: $primary,
  secondary: $secondary,
  success: $success,
  info: $info,
  warning: $warning,
  danger: $danger,
  light: $light,
  gray-100: $gray-100,
  gray-200: $gray-200,
  gray-300: $gray-300,
  gray-400: $gray-400,
  gray-500: $gray-500,
  gray-600: $gray-600,
  gray-700: $gray-700,
  gray-800: $gray-800,
  gray-900: $gray-900,
  dark: $dark,
) !default;

@import "dark/dark";

.grecaptcha-badge { 
  visibility: hidden;
}