@import "./../../../../styles/app/breakpoints";


.scrollbar-container.sidebar-left {
    display: none;
}

.main-content-wrap {
    min-height: calc(100vh - 80px);
    h1 {
        text-align: center;
        font-weight: bold;
    }

    h5 {
        text-align: center;
        margin-bottom: 12px;
    }

    .welcome-card {
        background: url("../../../../assets/img/ubstudio-welcome-desktop.webp") no-repeat center center transparent;
        background-size: cover;
        height: 400px;
        width: 80%;
        margin: 0 auto;
        position: relative;
        border-radius: 10px;

        @include breakpoint(mobileonly) {
            background: url("../../../../assets/img/ubstudio-welcome-mobile.webp") no-repeat center center transparent;
            height: 250px;
            background-size: cover;
        }

        @include breakpoint(mobileonly) {
            width: 100%;
        }
    }

    ul.welcome-advantages  {
        width: 80%;
        list-style: none;
        margin: 0 auto;
        padding: 0;
        text-align: center;
        position: relative;
        margin-top: -90px;

        @include breakpoint(mobileonly) {
            width: 100%;
            margin-top: 20px;
        }
        
        li:not([class^="button"]) {
            display: inline-block;
            text-align: center;
            width: 30%;
            background: #FFFFFF;
            box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.05);
            border-radius: 10px;
            margin-right: 20px;
            position: relative;
            padding: 60px 30px 20px 30px;


            @include breakpoint(mobileonly) {
                display: flex;
                flex-direction: row;
                width: 100%;
                margin-bottom: 20px;
                box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
                padding: 20px 10px;
                text-align: left;
            }

            img {
                display: block;
                position: absolute;
                width: 81px;
                left: 50%;
                margin-left: -40.5px;
                top: -40px;

                @include breakpoint(mobileonly) {
                    position: relative;
                    top: 0;
                    left: 0;
                    margin: 0;
                    width: 70px;
                    height: 70px;
                    margin-top: 7px;
                    margin-right: 7px;
                }
            }


            .text-section {
                .item-title {
                    display: block;
                    font-weight: bold;
                    font-size: 16px;
                    display: block;
                    margin: 0 auto;
                    margin-bottom: 10px;

                    @include breakpoint(mobileonly) {
                        width: 100%;
                        margin-bottom: 5px;
                        line-height: 18px;
                    }
                }

                .item-desc {
                    display: block;
                    font-size: 12px;
                    line-height: 14px;
                }
            }

            
        }

        li:nth-child(3) {
            margin-right: 0;
        }

        li.button {
            margin-top: 15px;
            margin-bottom: 15px;
            button {
                width: 200px;
            }

            @include breakpoint(mobileonly) {
                margin-bottom: 0px;
                button {
                    width: 80%;
                }
            }
        }
    }
}
