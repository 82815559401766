.auth-layout-wrap {
    h2 {
        color: white;
    }
    .LoginFail {
        color: red;
        text-align: center;
        display: inline-block;
        width: 100%;
        padding-bottom: 2px;
        border-bottom: 1px solid rgba(231, 76, 60,0.4);
        margin-bottom: 10px;
    }
    .forgot-pass {
        cursor: pointer;
    }
    .create-account {
        width: 100%;
        text-align: center;
        display: inline-block;
        margin-top: 10px;
        span.link {
            cursor: pointer;
            color: #FF5B00 !important;
            &:hover { text-decoration: underline; }
        }

    }
    .accept_terms {
        margin-right: 0px;
    }
    .use-terms {
        text-decoration: underline;
        font-weight: bold;
        font-size: 12px;
        cursor: pointer;
    }
}