.bttn-general {
    display: block;
    margin-left: auto !important;
    margin-right: auto !important;
}

.bttn-1-area {
    padding-bottom: 10px;

    .bttn-1 {
        width: 295px;
        border-radius: 20px;
    }
}

.bttn-2-area {
    .bttn-2 {
        color: black !important;
        background-color: white;
        border-color: black;
        border-radius: 20px;
    }

    .bttn-2:hover {
        background-color: white;
    }
}

.modal-title {
        display: block !important;
        margin-left: auto !important;
        margin-right: auto !important;
}