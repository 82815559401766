@media (max-width: 959px) {
    .header-topnav-right {
        position: absolute;
        right: 6px;
        top: 0;
    }
}


/* Media Queries
--------------------------------------------- */

@media all and (max-width: 330px) {
    .topnav ul li {
        display: block;
        width: 94%;
    }
}

[dir=rtl] {
    .topnav {
        a,
        label {
            .mat-icon {
                margin-right: 0;
                margin-left: 2px;
            }
        }
    }
}