@import "colors";
@import "sidebar-large/layout-sidebar-large";
@import "vertical-navbar/vertical-navbar";

// Common Layout Styles

// Sidebar multilevel dropdown
.sidebar-left-secondary {
  .childNav {
    li.nav-item {
      .dd-arrow {
        transform: rotate(-90deg);
      }
      &.open {
        > a {
          background: $gray-200;
          > .dd-arrow {
            transform: rotate(0deg);
          }
        }
      }
      .submenu {
        margin: 0;
        padding: 0;
        list-style: none;
        overflow: hidden;
        transition: all 0.3s ease-in;
        background: $gray-100;
        > li {
          a {
            padding-left: 50px;
          }
        }
      }
    }
  }
}

[dir="rtl"] {
  .notification-dropdown .dropdown-item .notification-details {
    text-align: right;
  }
  .main-header .user {
    margin-left: 2rem;
    margin-right: 0;
  }
  .sidebar-left-secondary {
    .childNav {
      li.nav-item {
        .dd-arrow {
          margin-right: auto;
          margin-left: unset;
        }
      }
    }
  }
}

.active-color {
  height: 40px;
  padding: 0 12px 0 24px;
  border-radius: 0 35px 35px 0;
  margin-right: 16px;
  background-color: #663399;
  color:#fff!important;
}