.bttn-general {
    display: block;
    margin-left: auto !important;
    margin-right: auto !important;
}

.bttn-1-area {
    padding-bottom: 10px;

    .bttn-1 {
        width: 295px;
        border-radius: 20px;
    }
}

.modal-title {
        display: block !important;
        margin-left: auto !important;
        margin-right: auto !important;
}