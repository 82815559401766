.header-dropdown-menu-component {

    .dropdown-menu {
        min-width: 250px;
    }

    .line {
        width: 100%;
        height: 2px;
        background: #F2F2F2;
        margin: 10px 0;
    }

    .header-icon {
        background: url("../../../../assets/img/sup-menu-icons.webp") no-repeat center center transparent !important;
        width: 25px !important;
        height: 25px !important;

        &.profile {
            background-position: -105px -4px !important;
        }

        &.help {
            background-position: -70px -4px !important;
        }

        &.logout {
            background-position: -36px -4px !important;
        }

        &.add {
            background-position: 1px -4px !important;
        }

        &.ubook {
            width: 40px !important;
            height: 40px !important;
            background-position: -142px 2px !important;
        }
    }

    .product-list {

        max-height: 225px;
        margin-bottom: 10px;
        overflow-y: auto;

    }

    .current_product {
        .image-wrapper {
            display: block;
            float: right;
            img {
                max-width: 100%;
                //a configuracao abaixo em cima do src corrige um problema de que o css estava mantendo e ignorando a imagem resgatada assincronamente nos componentes
                //que utilizam essas classes current_product
                //Com essa correcao as imagens voltaram a aparecer e caso não seja carregada/ocorra falha no resgate da imagem original, então no-user.png é utilizado normalmente
                &:not([src]) {
                    content: url("../../../../assets/img/no-user.png");
                }
            }
        }
    
        .product-title {
            display: none !important;
        }
    }

    .header-dropdown-menu-product-item {
        display: table;
        margin-bottom: 5px;
        padding: 0 10px;
        cursor: pointer;

        .image-wrapper {
            display: table-cell;
            vertical-align: middle;
            width: 40px !important;
            height: 40px !important;
            background: rgba(0,0,0,0.1);
            img {
                border-radius: 3px !important;
                width: 40px !important;
                height: 40px !important;
                display: block;
            }
        }

        .product-title {
            display: table-cell;
            vertical-align: middle;
            padding-left: 5px;
            font-size: 12px;
            line-height: 13px;
            color: #2C2C2C;
            font-weight: bold;
            cursor: pointer;
        }
    }

    .changed_product {
        padding-top: 5px;
        .header-dropdown-menu-product-item {
            cursor: default;
        }
    }

    a.dropdown-item {
        position: relative;
        font-weight: bold;
    }

    p.dropdown-item {
        position: relative;
        font-weight: bold;
    }
    p.dropdown-item {
        padding-left: 3rem;
        span.header-icon {
            position: absolute;
            left: 12px;
            top: 4px;
        }
    }
    a.dropdown-item {
        padding-left: 3rem;
        span.header-icon {
            position: absolute;
            left: 12px;
            top: 4px;
        }
    }
}
.header-loading-icon {
    position: absolute;
    left: 12px;
    top: 4px;
}