@import "../../../src/styles/app/breakpoints";

.header-separator {
    width: 1px;
    height: 32px;
    background: rgba(0,0,0,0.1);
    display: block;
}

.header-part-right {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    button {
        font-size: 15px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        @include breakpoint(mobileonly) {
            width: max-content;
        }

    }
}

.new-ep-header {
    @include breakpoint(mobileonly) {
        position: fixed;
        bottom:10px;
        right:10px;
        span {
            height: 25px;
            visibility: hidden;
            &:before {
                content: '+ Episódio'; 
                visibility: visible;
                display: block;
            }
        }
    }
}

.ubook-studio-header {
    background: #000000 !important;

    @include breakpoint(mobileonly) {
        display: flex !important;
        flex-direction: row !important;
        flex-wrap: nowrap !important;
    }

    .logo {
        width: 40%;
        max-width: 210px;
        min-width: 75px;

        img {
            width: auto !important;
            height: initial !important;
        }
    }
    
    .user {
        .dropdown-toggle {
            color: #fff;
        }
    }
}