@import "./../../../../styles/app/breakpoints";
.podcast-sidebar-component {
    width: 250px;
    height: 100%;
    top: 80px;
    background: #fff;
    position: fixed;

    z-index: 1000;

    .podcast-info {
        padding: 50px 50px;
        .cover-wrapper {
            &:hover {
                background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
                .cover {
                    opacity: 0.3;
                }
                .edit {
                    opacity: 1;
                }
                cursor: pointer;
            }
            display: flex !important;
            flex-direction: column !important;
            flex-wrap: nowrap !important;
            border-radius: 5px;
            width: 150px;
            height: inherit;
            overflow: hidden;
            .cover {
                border-radius: 5px;
            }
            .edit {
                position: absolute !important;
                top: 125px !important;
                left: 0% !important;
                transition: .5s ease;
                opacity: 0;
                display: flex;
                flex-direction: row-reverse;
                width: 100%;
                margin: 0 18px 0px;
                transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                text-align: center;
                .editImage {
                    height: 35px;
                }
              }
        }

        .title {
            font-weight: bold;
            text-align: center;
            margin-top: 10px;
            font-size: 16px;
            line-height: 16px;
        }

        .subscribers {
            text-align: center;
            color: #A7A7A7;
            margin-top: 10px;
            font-size: 12px;
            font-weight: bold;
        }
    }

    ul.menu {
        margin: 0;
        padding: 0 10px;
        li {
            a {
                color: #333;
                display: block;
                width: 100%;
                height:100%;
                padding: 10px 0;
            }

            list-style: none;
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 1px;
            padding-left: 52px;
            border-radius: 5px;
            cursor: pointer;
            position: relative;
            color: #333;
            height: 46px;

            span {
                width: 30px;
                height: 30px;
                background: url("../../../../assets/img/podcast-sidebar-icons.webp") no-repeat center center transparent;
                position: absolute;
                top: 7px;
                left: 12px;
                display: block;
            }

            &.publishUpdate {
                span {background: url("../../../../assets/img/update.svg") no-repeat center center transparent !important; }
            }

            &.panel {
                span {
                    background-position: -115px 5px;
                }
            }

            &.season {
                span {background: url("../../../../assets/img/seasons_sidebar.svg") no-repeat center center transparent !important; }
                
                &.active {
                    span {background: url("../../../../assets/img/seasons_sidebar_selected.svg") no-repeat center center transparent !important; }
                }
            }
            &.episodes {
                span {
                    background-position: -257px 5px;
                }
            }

            &.edit {
                span {background: url("../../../../assets/img/settings.svg") no-repeat center center transparent !important; }
            }

            &.plans {
                span {
                    background-position: -36px 5px;
                }
            }

            &.reports {
                span {
                    background-position: -75px 5px;
                }
            }
            &.rss {
                span {
                    background-position: -187px 5px;
                }
            }

            &.preferences {
                span {
                    background-position: 3px 5px;
                }
            }

            &.active {

                background: #F2F2F2;
                color: #FF5B00;

                a {
                    color: #FF5B00
                }

                &.panel {
                    span {
                        background-position: -115px -27px;
                    }
                }
    
                &.plans {
                    span {
                        background-position: -36px -27px;
                    }
                }
    
                &.reports {
                    span {
                        background-position: -75px -27px;
                    }
                }
    
                &.preferences {
                    span {
                        background-position: 3px -27px;
                    }
                }
                &.rss {
                    span {
                        background-position: -187px -27px;
                    }
                }
                &.episodes {
                    span {
                        background-position: -257px -27px;
                    }
                }
            }
        }
    }
}