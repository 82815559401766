.main-header {
  position: fixed;
  width: 100%;
  height: $topbar-height;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  background: $background;
  z-index: 100;
  
  .menu-toggle {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    margin-left: 20px;
    div {
      width: 24px;
      height: 1px;
      background: $menu-toggle;
      margin: 3px 0;
    }
  }

  .logo {
    img {
      width: 60px;
      height: 60px;
      margin: 0 auto;
      display: block;
    }
  }
  .show {
    .header-icon {
      background: $gray-100;
    }
  }
  .header-icon {
    font-size: 19px;
    cursor: pointer;
    height: 36px;
    width: 36px;
    line-height: 36px;
    display: inline-block;
    text-align: center;
    border-radius: 8px;
    margin: 0 2px;
    &:hover {
      background: $gray-100;
    }
    &.dropdown-toggle {
      &:after {
        display: none;
      }
    }
  }
  .notification-dropdown {
    padding: 0;
    max-height: 260px;
    overflow-y: scroll;
    cursor: pointer;
    .dropdown-item {
      display: flex;
      align-items: center;
      padding: 0;
      height: 72px;
      border-bottom: 1px solid $gray-300;
      .notification-icon {
        background: $gray-200;
        height: 100%;
        width: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        i {
          font-size: 18px;
        }
      }
      .notification-details {
        padding: 0.25rem 0.75rem;
      }
      &:active {
        color: inherit;
        background: inherit;
      }
    }
  }
}
