.auth-layout-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh);
    background-size: cover;
    background-image: url(assets/login-bg-ubook.jpg);
    //background-position: center center;
    background-repeat: no-repeat;
    background-color: rgba(0,0,0,0.6);


    .auth-content {
        width: 98%;
        max-width: 500px;
        margin: auto;
        padding: 0 15px 0 0 !important;

        @media(max-width: 600px) { 
            display: flex;
            flex-flow: column;
            justify-content: center;
            width: 100vw;
            max-width: 100vw;
            height: 100vh; 
            background: #2C2C2C;          
        }

        .card {
            width: 100%;
            max-width: 500px;
            background: #2C2C2C;
            @media(max-width: 600px) { 
                box-shadow: none 
            };
            
            label, span {
                color: #fff !important;
                &.link, &.use-terms { color: #FF5B00 !important; }
            }
            .row > *, .row {
                @media(max-width: 600px) { padding: 0 !important; }
            }
        }
    }
}

.auth-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.auth-logo {
    img {
        width: 250px;
    }
}

@media (min-width: 1024px) {
    .auth-layout-wrap {
        .auth-content {
            min-width: 400px;
        }
    }
}
@media (max-width: 767px) {
    .auth-layout-wrap {
        .auth-content {
            padding: 15px;
        }
    }
    .auth-right {
        padding: 80px 15px;
    }
}